import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form } from 'semantic-ui-react'
import ConfirmationModal from '../../../../../../components/confirmationModal';
import FiledsValidator from '../../../../../../utils/validation/fieldsValidator';
import DescriptorsForm from './descriptors/descriptorsForm';
import { SchemaOps } from './schemaOptions';
import ScaleUtil from '../../../../../../utils/scaleUtil';
import { DataCalcType } from '../../../../../../models/descriptorModel';
import KeyValue from '../../../../../../structures/keyValePair';
import { SchemaField } from '../../../../../../constnats/schemaConstants';
import { SelectField } from '../../../../../../components/form/fields';
import UserRepo from '../../../../../../utils/repository/userRepo';
import { ROLES } from '../../../../../../constnats/user';
import { SchemaNames } from '../../../../../../constnats/schemaConstants'; 
import AuthService from '../../../../../../service/auth';

const SCALE_MAP = ScaleUtil.scalePairs.filter(p => p.key !== 0)
const DATA_CALC_TYPES_MAP = DataCalcType.getAllTypes().map(KeyValue.createSame)
const VISIBILITY_MAP = ["PUBLIC", "INTERNAL"]
    .map(t => new KeyValue(t, t.toLowerCase()))

class SchemaForm extends React.Component {
    constructor(props) {
        super(props);
        this.filedsValidator = new FiledsValidator(["name"])

        const schemaName = this.props.schema.name
        this.state = {
            schema: Object.assign({}, this.props.schema),
            ownerId: this.props.schema.owner ? this.props.schema.owner.id : null,
            propsValidation: this.filedsValidator.getValidErrorProps(),
            modal: <></>,
            nameOptions: [
                { key: schemaName, text: schemaName, value: schemaName },
                { key: SchemaNames.KPIs, text: SchemaNames.KPIs, value: SchemaNames.KPIs },
                { key: SchemaNames.SEG_BY_PROD, text: SchemaNames.SEG_BY_PROD, value: SchemaNames.SEG_BY_PROD },
                { key: SchemaNames.SEG_BY_REG, text: SchemaNames.SEG_BY_REG, value: SchemaNames.SEG_BY_REG },
            ]
        }
    }

    onPropChange(newVal, prop) {
        const schemaCopy = Object.assign({}, this.state.schema)
        schemaCopy[prop] = newVal
        this.setState({ schema: schemaCopy })
        this.props.onChange()
        this.setState({
            propsValidation:
                this.filedsValidator.dicardErrorIfExists(prop, this.state.propsValidation)
        })
    }

    getNameField() {
        const propName = "name"
        return <Form.Field>
            <label>Name</label>
            <Dropdown options={this.state.nameOptions} placeholder='Choose Name'
                search selection fluid allowAdditions additionLabel='Custom Name: '
                value={this.state.schema.name}
                onAddItem={(e, { value }) => {
                    this.setState((prevState) => ({
                        nameOptions: [{ key: value, text: value, value }, ...prevState.nameOptions],
                    }))
                }}
                onChange={(e, { value }) => this.onPropChange(value, propName)}
                error={this.state.propsValidation[this.filedsValidator.getErrorPropName(propName)] !== false}
            />
        </Form.Field>
    }

    visibilityField() {
        return <Form>
            <SelectField value={this.state.schema[SchemaField.VISIBILITY]}
                valuesMap={VISIBILITY_MAP} number={false} label="Visibility" className="thinField"
                onChange={v => this.onPropChange(v, SchemaField.VISIBILITY)} 
                disabled={!AuthService.hasGlobalRole(ROLES.QA)}
                />
        </Form>
    }

    schemaConfig() {
        const schema = this.state.schema

        return <Form>
            <SelectField value={schema[SchemaField.DEF_DATA_CALC_TYPE]}
                valuesMap={DATA_CALC_TYPES_MAP} number={false} label="Default Data Calc. Type" className="thinField"
                onChange={v => this.onPropChange(v, SchemaField.DEF_DATA_CALC_TYPE)} />
            <SelectField value={schema[SchemaField.DEF_SCALE]}
                valuesMap={SCALE_MAP} number={true} label="Default Scale" className="thinField"
                onChange={v => this.onPropChange(v, SchemaField.DEF_SCALE)} />
        </Form>
    }

    validate() {
        const validationRes = this.filedsValidator.validate(this.state.schema)
        this.setState({ propsValidation: validationRes.newErrorProps })
        return validationRes.valid
    }

    save() {
        if (this.validate()) {
            this.props.save(Object.assign({}, this.state.schema, { ownerId: this.state.ownerId }),
                () => {
                    const currentOwnerId = this.props.schema.owner ? this.props.schema.owner.id : null;
                    if (currentOwnerId !== this.state.ownerId) {
                        window.location.reload()
                    }
                })
        }
    }

    deleteSchema() {
        this.openModal(
            <ConfirmationModal
                msg="The schema and all reports associated with it will be permantly deleted. Are you sure that you want to delete them?"
                onCancel={() => this.closeModal()}
                onConfirm={() => {
                    this.props.delete()
                    this.closeModal()
                }} />)
    }

    closeModal() {
        this.setState({ modal: <></> })
    }

    openModal(modal) {
        this.setState({ modal: modal })
    }

    render() {
        const allowdOps = this.props.options
        const updateDescriptorsAllowed = allowdOps.updateDescriptors === true
        const visibilityUpdate = allowdOps.visibilityUpdate === true
        const updateName = allowdOps.updateName === true
        const deleteAllowed = allowdOps.delete === true
        const changeOwnership = allowdOps.changeOwnership === true
        const contextMenuOps = this.props.contextMenuOps.slice()

        const createMode = allowdOps.createMode === true

        const schema = this.state.schema
        return (
            <>
                {this.state.modal}
                <SchemaOps options={contextMenuOps} />

                {visibilityUpdate && this.visibilityField()}
                <Form>
                    {changeOwnership && <Owner ownerId={this.state.ownerId}
                        onChange={newId => this.setState({ ownerId: newId })} />}
                    {updateName === true && this.getNameField()}
                </Form>
                {updateDescriptorsAllowed && this.schemaConfig()}
                <br />
                <DescriptorsForm
                    descriptors={schema[SchemaField.DESCRIPTORS]}
                    onChange={newDescriptors => this.onPropChange(newDescriptors, SchemaField.DESCRIPTORS)}
                    readOnly={!updateDescriptorsAllowed}
                    defaultDataCalcType={schema[SchemaField.DEF_DATA_CALC_TYPE]}
                    defaultScale={schema[SchemaField.DEF_SCALE]} />
                <div className='formButtons'>

                    {(updateDescriptorsAllowed || visibilityUpdate || updateName || changeOwnership)
                        && <Button onClick={() => this.save()} size='mini' primary>{createMode ? "Create" : "Save"}</Button>}

                    {deleteAllowed &&
                        <Button onClick={() => this.deleteSchema()} size='mini' color='red'>Delete</Button>}
                </div>
            </>
        );
    }
}

function Owner({ ownerId, onChange }) {
    let [users, setUsers] = useState(null)

    useEffect(() => {
        UserRepo.list({
            userRoles: [ROLES.EMPLOYEE],
            includeDisabled: false
        }, users => {
            if (!users.find(u => u.id === ownerId)) {
                UserRepo.get(ownerId, owner => {
                    owner.fullName = owner.fullName + " (DELETED)"
                    users.push(owner)
                    setUsers(users)
                })
            } else {
                setUsers(users)
            }
        })
    }, [ownerId])

    return users === null || <SelectField value={ownerId}
        valuesMap={users.map(u => new KeyValue(u.id, u.fullName))} number={false}
        label="Owner" className="thinField"
        onChange={onChange} />
}



export default SchemaForm


