import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

const bottomStickyScrollbar = ({children, className}) => {
    const renderThumb = ({ style, ...props }) => (
        <div
            {...props}
            style={{
                ...style,
                backgroundColor: `black`,
                height: '8px',
                borderRadius: '10px',
            }}
        />
    );

    const renderTrackHorizontal = ({ style, ...props }) => (
        <div
            className="ppp"
            id="h-scroll"
            {...props}
            style={{
                ...style,
                width: '100%',
                bottom: 0,
                position: 'sticky',
                height: '9px',
                opacity: 0.3,
                zIndex: 99,
            }}
        />
    );

    return (
        <Scrollbars
            className={className}
            autoHeightMin="100%"
            autoHeightMax="100%"
            autoHeight
            renderThumbHorizontal={renderThumb}
            renderTrackHorizontal={renderTrackHorizontal}
        >
            {children}
        </Scrollbars>
    );
};

export default bottomStickyScrollbar;

