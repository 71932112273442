import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Button, Divider, Icon, Popup, Segment } from 'semantic-ui-react';
import Utils from '../../../../utils/utils';
import ObjCheck from '../../../../utils/objCheck';
import { DATA_SOURCES, MetadataField, ReportField, ReportMetadataField } from '../../../../constnats/reportConstants';
import ScaleUtil from '../../../../utils/scaleUtil';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Assert from '../../../../utils/asserts';
import { default as DescriptorUtil } from "../../../../utils/descriptor/descriptorUtils"
import ReportUtils from '../../../../utils/reportUtils';
import { CalculationExpUtil } from '../../../../utils/descriptor/calculationExp';
import AuthService from '../../../../service/auth';
import { ROLES } from '../../../../constnats/user';
import { NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT } from '../../../../messages';

import CellChart from './cellChart';
import TableChart from './tableChart';
import { labelsSimilarityCheck, normalizeLabel } from '../../../../utils/labels';
import TableActionButton from '../../../../components/buttons/tableActionButton';
import { DescriptorProps, StaticDescriptorsIds } from '../../../../constnats/descriptor';
import DescriptorType from '../../../../utils/descriptor/descriptorType';
import { CURRENT_TO_PAST_PERIODS_MAP } from '../../../../constnats/periods';
import { getCorrespondingPeriodGrowthHeading } from '../../../../utils/periodsUtil';
import { YEAR_OVER_YEAR_GROWTH_HEADING } from '../../../../constnats/growth';
import BottomStickyScrollbar from '../../../../components/bottomStickyScrollbar';
import { ICON_COLORS } from '../../../../constnats/icon';

const FONT_WEIGHT_BOLD_STYLE = { fontWeight: "bold" }

const anonnymousClientsAllowed = process.env.REACT_APP_ANONYMOUS_CLIENTS_ALLOWED === "true"

class CellEntity {
    constructor(data, metadata, classes, style = {}, tooltip, onClick, contentPrefix, getChart, colSpan = 1) {
        this.data = data
        this.metadata = metadata
        this.classes = classes
        this.style = style
        this.tooltip = tooltip
        this.onClick = onClick
        this.contentPrefix = contentPrefix
        this.getChart = getChart
        this.colSpan = colSpan
    }

    static createForP2PGrowth(cellData, classes) {
        return new CellEntity(cellData, {}, "growth-percentage " + classes)
    }
}

class RowEntity {
    constructor(id, entities, descriptorStyle, classNames) {
        this.id = id
        this.entities = entities
        this.style = descriptorStyle
        this.classNames = classNames
    }
}

/**
 * 
 * @param {Object} fieldMetadata Required
 * @param {string} descriptorLabel Required
 * @param {string} descriptorLabelNormalized Required
 * @param {Function} normalizeDataWrapper Required 
 * @param {boolean} hasPreviewResourcesPrivs Required 
 * @param {boolean} hasPreviewLablesPrivs Required
 * @param {string} [dataSource] Optional
 * @returns not-null
 */
function getCellMetdata(fieldMetadata, descriptorLabel,
    descriptorLabelNormalized, normalizeDataWrapper, hasPreviewResourcesPrivs,
    hasPreviewLablesPrivs, dataSource) {
    const metadata = {}

    const reportedAsLabel = fieldMetadata[MetadataField.REPORTED_AS_LABEL]
    if (!ObjCheck.isNullUndefinedEmptyOrDash(reportedAsLabel)
        && !labelsSimilarityCheck(descriptorLabel, descriptorLabelNormalized, reportedAsLabel)) {
        metadata[MetadataField.REPORTED_AS_LABEL] = reportedAsLabel
        metadata.hasPreviewLablesPrivs = hasPreviewLablesPrivs
    }

    if (fieldMetadata[MetadataField.MANUALLY_CALCED] === true) {
        metadata[MetadataField.MANUALLY_CALCED] = true;
    }

    if (!ObjCheck.isNullOrUndefined(fieldMetadata[MetadataField.SRC])) {
        metadata[MetadataField.SRC] = fieldMetadata[MetadataField.SRC];
    }

    const notes = fieldMetadata[MetadataField.NOTES]
    if (!ObjCheck.isNullUndefinedOrEmpty(notes)) {
        metadata[MetadataField.NOTES] = notes;
    }

    const p2pCalc = fieldMetadata[MetadataField.P2P_CALC]
    if (p2pCalc !== undefined) {
        metadata[MetadataField.P2P_CALC] = p2pCalc
    }

    const dataDiffDetction = fieldMetadata[MetadataField.DATA_DIFF_DETECTION]
    if (dataDiffDetction !== undefined) {
        metadata[MetadataField.DATA_DIFF_DETECTION] = dataDiffDetction
        Assert.notNullOrUndefined(fieldMetadata[MetadataField.DATA_DIFF_EXPECTATION], "getCellMetdata->dataDiffDetction")
    }

    const dataDiffExp = fieldMetadata[MetadataField.DATA_DIFF_EXPECTATION]
    if (dataDiffExp !== undefined) {
        metadata[MetadataField.DATA_DIFF_EXPECTATION] = normalizeDataWrapper(dataDiffExp)
        Assert.notNullOrUndefined(metadata[MetadataField.DATA_DIFF_DETECTION], "getCellMetdata->dataDiffExp")
    }

    if (fieldMetadata.resourceFilterProvider !== undefined) {
        metadata.resourceFilterProvider = fieldMetadata.resourceFilterProvider
        metadata.hasPreviewResourcesPrivs = hasPreviewResourcesPrivs
    }

    if (fieldMetadata.editFunc !== undefined) {
        metadata.editFunc = fieldMetadata.editFunc
    }

    if (dataSource) {
        metadata.dataSource = dataSource
    }

    return metadata
}

function getDescriptosStyle(descriptor) {
    const style = descriptor.style === undefined ? {} : JSON.parse(JSON.stringify(descriptor.style))
    if (style.backgroundColor !== undefined) {
        Object.assign(style, DescriptorUtil.is3dRatio(descriptor) ? {} : FONT_WEIGHT_BOLD_STYLE)

        if (style.backgroundColor === "#8fceff") {
            //TODO
            style.backgroundColor = '#b3d9ff'
        }
    }

    if (descriptor.note === true) {
        style.color = "#9f9d9d"
    }

    return style
}

function getHeaderTooltip(reports, descriptor, dynamicFlatDescriptors,
    hasPreviewLablesPrivs, categoryBasedMode) {
    const descriptorCategory = descriptor[DescriptorProps.CATEGORY]

    const label = descriptor[DescriptorProps.LABEL]
    let tooltipContent
    const reportedAsLabelsSet = new Set()
    const reportedAsLabels = []
    const category = descriptorCategory ? <>
        <Divider />
        <b>Category: </b> {descriptorCategory.name}
    </> : <></>

    for (const report of reports) {
        const label = ReportUtils.getFieldMetadata(report, descriptor.id)?.reportedAsLabel
        if (label && !reportedAsLabelsSet.has(normalizeLabel(label)) && label !== "-") {
            reportedAsLabelsSet.add(normalizeLabel(label))
            reportedAsLabels.push(label)
        }
    }

    if (DescriptorUtil.hasCalculation(descriptor) || reportedAsLabels.length > 1) {
        tooltipContent = <>
            <b>{label}</b>
            <Divider />
            {!categoryBasedMode && DescriptorUtil.hasCalculation(descriptor) && <><b>Calculation:</b>
                {CalculationExpUtil.originalToListComponent(descriptor[DescriptorProps.CALCULATION], dynamicFlatDescriptors)}</>}
            {!categoryBasedMode && DescriptorUtil.hasSecondaryCalculation(descriptor) && <><b>Alternative Calculation:</b>
                {CalculationExpUtil.originalToListComponent(descriptor[DescriptorProps.SECONDARY_CALCULATION], dynamicFlatDescriptors)}</>}
            {reportedAsLabels.length > 1 && <>
                <b> Reported as:</b >
                {hasPreviewLablesPrivs ?
                    <ol>{reportedAsLabels.map((l, i) => <li key={i}>{l}</li>)}</ol>
                    : NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT}
            </>}
            {category}
        </>

    } else {
        tooltipContent = <>
            {label}
            {category}
        </>
    }

    return tooltipContent
}

function getHeaderLabel(descriptor, categoryBasedMode) {
    const descriptorCategory = descriptor[DescriptorProps.CATEGORY]
    let label
    if (descriptorCategory && categoryBasedMode) {
        label = descriptorCategory.name
    } else {
        label = descriptor[DescriptorProps.LABEL]
    }
    return label
}

function getHeaderEntities(descriptor, level, scaleColumn, descriptorStyle,
    scaleFactor, expandCollapsManager, dynamicFlatDescriptors, reports,
    hasPreviewLablesPrivs, categoryBasedMode) {

    let label = getHeaderLabel(descriptor, categoryBasedMode)
    const entities = []
    const scale =
        ScaleUtil.numToTextRepresentation(scaleFactor, true)
    const headerLevelClass = "tableHeaderLevel_" + level

    const cellStyle = descriptorStyle.backgroundColor !== undefined ?
        { backgroundColor: descriptorStyle.backgroundColor } : { backgroundColor: "#FFF" }

    const onClick = expandCollapsManager.getOnClick(descriptor.id) || (() => { })
    const contentPrefix = expandCollapsManager.getIcon(descriptor.id)

    const tooltipContent = getHeaderTooltip(reports, descriptor,
        dynamicFlatDescriptors, hasPreviewLablesPrivs, categoryBasedMode)

    if (scaleColumn === true) {
        entities.push(new CellEntity(label, {}, headerLevelClass, cellStyle, tooltipContent, onClick, contentPrefix))
        entities.push(new CellEntity(DescriptorType.isNumber(descriptor)
            ? scale : "-", {}, "scaleColumn", cellStyle))

    } else {
        label = DescriptorType.isNumber(descriptor) ? (label + (" (" + scale + ")")) : label
        entities.push(new CellEntity(label, {}, headerLevelClass, cellStyle, tooltipContent, onClick, contentPrefix))
    }

    return entities
}

function getData(descriptor, report) {
    const dynamicField = descriptor.dynamicField
    const descriptorId = descriptor.id
    let data
    if (dynamicField) {
        data = report[ReportField.DYNAMIC_FIELDS][descriptorId]
    } else {
        data = report[descriptorId]
    }

    return data
}


function normalizeData(descriptor, data, scaleFactor, numberLocale,
    floatingPointPrecision, percentPrecision, addTrailingZeros) {
    if (ObjCheck.isNullUndefinedEmptyOrDash(data) &&
        !DescriptorType.isHeadline(descriptor)) {
        data = "-"
    } else if (DescriptorType.isNumber(descriptor)) {
        if (Utils.isNumber(data)) {
            const scaledNum = Utils.toNumber(data) / scaleFactor
            const cfg = { maximumFractionDigits: floatingPointPrecision }
            if (addTrailingZeros) {
                cfg.minimumFractionDigits = floatingPointPrecision
            }
            data = scaledNum.toLocaleString(numberLocale, cfg)
        } else {
            Assert.fail("Unexpected number value:" + data)
            data = "ERROR"
        }
    } else if (data !== "-" && DescriptorType.isPercent(descriptor)) {
        try {
            if (data.endsWith("%")) {
                const percentNum = Utils.toNumber(data.replace("%", ""), false)
                const cfg = { maximumFractionDigits: percentPrecision }
                if (addTrailingZeros) {
                    cfg.minimumFractionDigits = percentPrecision
                }
                data = percentNum.toLocaleString(numberLocale, cfg) + "%"
            } else {
                data = "ERROR"
            }
        } catch {
            Assert.fail("Unexpected percent value:" + data)
            data = "ERROR"
        }
    }

    return data
}


function getScaleFactor(descriptor, prefferedScale) {
    const defaultViewScale = descriptor[DescriptorProps.VIEW_SCALE]
    let scaleFactor
    if (descriptor[DescriptorProps.SCALABLE]) {
        scaleFactor = prefferedScale === 0 ? defaultViewScale : prefferedScale
    } else {
        scaleFactor = defaultViewScale
    }

    return scaleFactor
}

/**
 * 
 * @param {Object} config Required
 * @param {boolean} config.showYearlyGrowth Required
 * @param {boolean} config.showPeriodGrowth Required
 * @param {Object} fieldKnowledge Required
 * @param {boolean} fieldKnowledge.isHeadlineData Required
 * @param {Object} fieldKnowledge.descriptor Required
 * @param {Object} fieldKnowledge.fieldSpecificMetadata Required
 * @param {Object} primaryCellKnowledge 
 * @param {Object} primaryCellKnowledge.style Required
 * @param {Function | undefined} primaryCellKnowledge.getChartFunc Optional
 * @param {Object} primaryCellKnowledge.cellMetadata Required
 * @param {string} primaryCellKnowledge.normalizedData Required
 * @param {Object} report Required 
 * @returns {Array.<CellEntity>} not-null
 */
function getFieldCells(config, fieldKnowledge, primaryCellKnowledge, report) {
    const { showYearlyGrowth, showPeriodGrowth } = config
    const { isHeadlineData, descriptor, fieldSpecificMetadata } = fieldKnowledge
    const { style, getChartFunc, cellMetadata, normalizedData } = primaryCellKnowledge

    const p2pGrowthApplicable = descriptor[DescriptorProps.DYNAMIC] || descriptor[DescriptorProps.ID] === StaticDescriptorsIds.SOURCE

    const getP2PGrowthCellData = (growthMetadataProp) => {
        if (isHeadlineData) {
            return null
        } else if (descriptor[DescriptorProps.ID] === StaticDescriptorsIds.SOURCE) {
            return growthMetadataProp === MetadataField.YEARLY_GROWTH_PERCENT
                ? YEAR_OVER_YEAR_GROWTH_HEADING : getCorrespondingPeriodGrowthHeading(report[ReportField.PERIOD])
        } else if (ObjCheck.isNullOrUndefined(fieldSpecificMetadata[growthMetadataProp])) {
            return "-";
        } else {
            return `${fieldSpecificMetadata[growthMetadataProp]}%`
        }
    }

    const getP2PGrowthCell = (growthMetadataProp, classes) => {
        return p2pGrowthApplicable ? CellEntity.createForP2PGrowth(
            getP2PGrowthCellData(growthMetadataProp),
            classes) : null
    }

    const cellsForField = []
    if (showYearlyGrowth) {
        cellsForField.push(getP2PGrowthCell(MetadataField.YEARLY_GROWTH_PERCENT, "year"))
    }
    if (showPeriodGrowth && !!CURRENT_TO_PAST_PERIODS_MAP[report[ReportField.PERIOD]]) {
        cellsForField.push(getP2PGrowthCell(MetadataField.PERIOD_GROWTH_PERCENT, "period"));
    }

    cellsForField.unshift(new CellEntity(normalizedData, cellMetadata, undefined,
        style, undefined, undefined, undefined, getChartFunc, 1 + cellsForField.filter(x => x === null).length
    ))

    return cellsForField.filter(x => x)
}


function toRowEntities(descriptors, reports, level, scaleColumn, prefferedScale,
    numberLocale, hideEmptyRows, showImportantRowsOnly, expandCollapsManager,
    floatingPointPrecision, addTrailingZeros, showNotes, show3dRatios, dynamicFlatDescriptors, categoryBasedMode, showYearlyGrowth, showPeriodGrowth, showOriginalCellDataIfLatestMissing) {

    const percentPrecision = floatingPointPrecision > 2 ? 2 : floatingPointPrecision
    const rowEntities = []
    const hasPreviewResourcesPrivs = AuthService.hasAnyGlobalRole([ROLES.READ_RESOURCES, ROLES.EMPLOYEE]) || anonnymousClientsAllowed
    const hasPreviewLablesPrivs = AuthService.hasAnyGlobalRole([ROLES.READ_REPORTED_AS_LABELS, ROLES.EMPLOYEE]) || anonnymousClientsAllowed
    for (const descriptor of descriptors) {
        if (showNotes !== true && descriptor[DescriptorProps.NOTE] === true) {
            continue;
        }
        const descriptorLabel = descriptor[DescriptorProps.LABEL]
        const descriptorLabelNormalized = normalizeLabel(descriptorLabel)
        const descriptorStyle = getDescriptosStyle(descriptor)
        const scaleFactor = getScaleFactor(descriptor, prefferedScale)
        const cellEntities = []
        const descriptorId = descriptor.id
        const isHeadline = DescriptorType.isHeadline(descriptor)
        let hasData = false
        for (const report of reports) {
            let data = getData(descriptor, report)
            let dataSource;
            const fieldSpecificMetadata = ReportUtils.getFieldMetadata(report, descriptorId) || {}
            if (ObjCheck.isNullUndefinedEmptyOrDash(data)) {
                if (fieldSpecificMetadata[MetadataField.P2P_CALC_RES] !== undefined) {
                    data = fieldSpecificMetadata[MetadataField.P2P_CALC_RES]
                    dataSource = DATA_SOURCES.P2P_CALC
                } else if (fieldSpecificMetadata[MetadataField.DATA_DIFF_EXPECTATION] && !showOriginalCellDataIfLatestMissing) {
                    data = fieldSpecificMetadata[MetadataField.DATA_DIFF_EXPECTATION]
                    dataSource = DATA_SOURCES.DATA_DIFF
                }
            }
            const normalizeDataWrapper = dataToNormalize => {
                return normalizeData(descriptor, dataToNormalize, scaleFactor, numberLocale,
                    floatingPointPrecision, percentPrecision, addTrailingZeros)
            }
            const normalizedData = normalizeDataWrapper(data)

            const metadata = getCellMetdata(fieldSpecificMetadata,
                descriptorLabel, descriptorLabelNormalized, normalizeDataWrapper,
                hasPreviewResourcesPrivs, hasPreviewLablesPrivs, dataSource)

            if (!ObjCheck.isNullUndefinedEmptyOrDash(normalizedData)) {
                hasData = true
            }


            let style = {}
            if (ReportUtils.getMetadataProp(report, ReportMetadataField.OBSOLETE)) {
                style = { backgroundColor: "#b0b3b6", color: "#777777" }
            } else if (ReportUtils.getMetadataProp(report, ReportMetadataField.DUPLICATE)) {
                style = { backgroundColor: "#fc2c2c" }
            }

            const getChartFunc = (DescriptorType.isNumber(descriptor) || DescriptorType.isPercent(descriptor))
                ? () => <CellChart srcReport={report} fieldId={descriptorId} fieldName={descriptorLabel} {...{ reports }} />
                : undefined


            cellEntities.push(...getFieldCells(
                { showYearlyGrowth, showPeriodGrowth },
                { isHeadlineData: isHeadline, descriptor, fieldSpecificMetadata },
                { style, getChartFunc, cellMetadata: metadata, normalizedData },
                report))
        }

        const subRowEntities = toRowEntities(
            descriptor[DescriptorProps.SUBFIELDS], reports, level + 1,
            scaleColumn, prefferedScale, numberLocale,
            hideEmptyRows, showImportantRowsOnly, expandCollapsManager, floatingPointPrecision,
            addTrailingZeros, showNotes, show3dRatios, dynamicFlatDescriptors, categoryBasedMode, showYearlyGrowth, showPeriodGrowth, showOriginalCellDataIfLatestMissing)

        expandCollapsManager.updateDescriptor(descriptorId, subRowEntities.length > 0)
        cellEntities.unshift(...getHeaderEntities(descriptor, level, scaleColumn,
            descriptorStyle, scaleFactor, expandCollapsManager,
            dynamicFlatDescriptors, reports, hasPreviewLablesPrivs, categoryBasedMode))

        const matchImportanceFilter = showImportantRowsOnly === false || descriptor[DescriptorProps.IMPORTANCE] < 5
        const matchHasDataFilter = hasData || !hideEmptyRows || isHeadline
        const matchCategoryFilter = (categoryBasedMode && descriptor[DescriptorProps.DYNAMIC]) ? !!descriptor[DescriptorProps.CATEGORY] : true 
        if (matchHasDataFilter && matchImportanceFilter && matchCategoryFilter) {
            //BUG a parent row may have not any data but its child can have
            const ratio = DescriptorUtil.is3dRatio(descriptor)
            if (ratio && !show3dRatios) {
                //Skip
            } else {
                rowEntities.push(new RowEntity(descriptorId, cellEntities, descriptorStyle,
                    isHeadline ? "headerRow" : (ratio ? "ratioRow" : "")))
            }
        }

        rowEntities.push(...subRowEntities)
    }

    return rowEntities
}

// Refactore or replace with state inside the cells
class ExpandCollapsManager {

    constructor(descriptors, onChange) {
        this.rowsState = {}
        this.onChange = onChange
        this._init(descriptors)
        this.collapsedRows = {}
    }

    updateDescriptor(descriptorId, collapseExpandEnabled) {
        this.rowsState[descriptorId].enabled = collapseExpandEnabled
    }

    getOnClick(descriptorId) {
        return this.rowsState[descriptorId].onClick
    }

    getIcon(descriptorId) {
        let icon
        const state = this.rowsState[descriptorId]
        if (state.subIds.length === 0 || state.enabled === false) {
            icon = ""
        } else {
            icon = <Icon className='collapseExpandI' size="tiny"
                name={state.expanded ? "minus" : "plus"} />
        }

        return icon
    }

    _init(descriptors) {
        const ids = []
        for (const descriptor of descriptors) {
            const descriptorId = descriptor.id
            ids.push(descriptorId)
            const subIds = this._init(descriptor[DescriptorProps.SUBFIELDS])
            ids.push(...subIds)

            this.rowsState[descriptorId] = {
                subIds: subIds,
                expanded: true,
                enabled: true,
                onClick: () => {
                    const cfg = this.rowsState[descriptorId]
                    this._updateCollapsedRows(cfg.subIds, cfg.expanded === true)
                    cfg.expanded = !cfg.expanded
                }
            }
        }

        return ids
    }


    collapseExpandAll(collapse) {
        for (const descriptorId of Object.keys(this.rowsState)) {
            const row = this.rowsState[descriptorId]
            row.expanded = !collapse

            for (const subDescriptorId of row.subIds) {
                this.collapsedRows[subDescriptorId] = collapse
            }

        }
        this.onChange(JSON.parse(JSON.stringify(this.collapsedRows)))
    }


    _updateCollapsedRows(rowIds, collapse) {
        for (const id of rowIds) {
            if (collapse) {
                this.collapsedRows[id] = true
            } else {
                delete this.collapsedRows[id]
                this.rowsState[id].expanded = true
            }
        }

        this.onChange(JSON.parse(JSON.stringify(this.collapsedRows)))
    }
}

// Copmonents START
function Table({ reports, descriptors, equalRowsHeight, scaleColumn,
    headerColumnWidth, headerColumnFontSize, numberLocale, prefferedScale,
    hideStandaloneCalculatedCells, hideEmptyRows, showImportantRowsOnly, periodClasses,
    floatingPointPrecision, addTrailingZeros, showNotes, show3dRatios, verticals, horizontals, dynamicFlatDescriptors,
    showInfoIcons, showCharts, categoryBasedMode, currencyInfo, advancedSettingsBtn, addReportBtn, chartsRef, showYearlyGrowth, showPeriodGrowth, showOriginalCellDataIfLatestMissing }) {

    // hideStandaloneCalculatedCells currently disabled
    const [collapsedRows, setCollapsedRows] = useState({});
    const [allCollapsed, setAllCollapsed] = useState(true)
    const [expandCollapsManaget] = useState(() =>
        new ExpandCollapsManager(descriptors, setCollapsedRows));
    const headerRefs = [useRef(null), useRef(null), useRef(null), useRef(null)]

    //TODO fix this hack
    const excelExporter = AuthService.hasRole(AuthService.getAuthContext(), ROLES.EXPORT_TO_EXCEL)

    function getPeriodClass(period) {
        return periodClasses[period[0]]
    }

    function getColSpan(report) {
        const hasPeriodToPeriodGrowth = CURRENT_TO_PAST_PERIODS_MAP[report[ReportField.PERIOD]];
        let colSpan = 1;

        if (showYearlyGrowth) {
            colSpan++;
        }

        if (showPeriodGrowth && hasPeriodToPeriodGrowth) {
            colSpan++;
        }

        return colSpan
    }

    function colConfigs() {
        const cols = [<col key="header" />];
        if (scaleColumn) {
            cols.push(<col key="scale" />);
        }
        cols.push(
            ...reports.map((r, idx) => {
                return <col span={getColSpan(r)} key={idx} className={getPeriodClass(r.period)} />;
            })
        );

        return cols;
    }


    const rowEntities = toRowEntities(descriptors, reports, 0, scaleColumn,
        prefferedScale, numberLocale,
        hideEmptyRows, showImportantRowsOnly, expandCollapsManaget, floatingPointPrecision,
        addTrailingZeros, showNotes, show3dRatios, dynamicFlatDescriptors, categoryBasedMode, showYearlyGrowth, showPeriodGrowth, showOriginalCellDataIfLatestMissing)

    function getRows(headerRefs) {
        return rowEntities
            .filter(re => collapsedRows[re.id] !== true)
            .map((rowEntity, idx) => idx < headerRefs.length
                ? <Row headerRef={headerRefs[idx]} key={idx}
                    rowEntity={rowEntity} showInfoIcons={showInfoIcons} /> :
                <Row key={idx} rowEntity={rowEntity} showInfoIcons={showInfoIcons} />)
    }

    function collapseExpandAll() {
        expandCollapsManaget.collapseExpandAll(allCollapsed)
        setAllCollapsed(!allCollapsed)
    }

    const baseTableClases = "reportsTable headerWidth_" + headerColumnWidth + ' headerFontSize_'
        + headerColumnFontSize + " headerWidth_" + headerColumnWidth + (equalRowsHeight ? ' equalRowHeight' : "")
        + (verticals ? "" : ' noVerticals')
        + (horizontals ? "" : ' noHorizontals')

    const tableRef = useRef(null)

    useFixedHeader(tableRef, headerRefs)

    const visibleFieldIds = rowEntities.map(r => r.id)

    return <>
        <div style={{ position: "relative" }} className="publicReportsTableWrapper" >
            <Segment className='table-actions-container-wrapper' basic size='mini'>
                {currencyInfo}
                <div className='table-actions-container'>
                    <TableActionButton onClick={() => collapseExpandAll()}>
                        {allCollapsed ? 'Collapse ' : "Expand "} all nested rows
                    </TableActionButton>
                    {addReportBtn}
                    {excelExporter && (
                        <DownloadTableButton tableRef={tableRef} />
                    )}
                    {advancedSettingsBtn}
                </div>
            </Segment>

            <BottomStickyScrollbar>
                <table ref={tableRef} className={baseTableClases}>
                    <colgroup>
                        {colConfigs()}
                    </colgroup>
                    <tbody>
                        {getRows(headerRefs)}
                    </tbody>
                </table>
            </BottomStickyScrollbar>
        </div>

        <div ref={chartsRef}>
            {showCharts &&
                <Segment>
                    <TableChart {...{ reports, dynamicFlatDescriptors: dynamicFlatDescriptors.filter(d => visibleFieldIds.includes(d.id)) }} />
                </Segment>
            }
        </div>
        {AuthService.hasAnyGlobalRole([ROLES.EMPLOYEE]) && <TableManipolationBtn />}
    </>
}

/**
 * @description Due to problems with the npm package react-export-table-to-excel we need to force a rerender on their 
 * provided component. For some reason when the component is initially rendered it says that:
 * currentTableRef or tablePayload does not exist, by rerendering the component the issues is fixed.
 * The purpose of this component is to do a rerender after the initial render 
 */
function DownloadTableButton({ tableRef }) {
    const [_, forceUpdate] = useState(false)
    useEffect(() => {
        forceUpdate(true)
    }, [])
    return (
        <DownloadTableExcel
            filename="file"
            sheet="Sheet 1"
            currentTableRef={tableRef.current}
        >
            <TableActionButton> Export to excel </TableActionButton>
        </DownloadTableExcel>
    )
}

function Row({ rowEntity, headerRef, showInfoIcons }) {

    return headerRef === undefined ? <tr style={rowEntity.style} className={rowEntity.classNames} >
        {rowEntity.entities.map((entity, i) =>
            <Cell key={i} cellEntity={entity} showInfoIcons={showInfoIcons} />)}
    </tr> : <tr ref={headerRef} style={rowEntity.style} className={rowEntity.classNames} >
        {rowEntity.entities.map((entity, i) =>
            <Cell key={i} cellEntity={entity} showInfoIcons={showInfoIcons} />)}
    </tr>
}

function Cell({ cellEntity, showInfoIcons }) {
    const dataSpan = <DataSpan {...{ cellEntity }} />
    return (<>
        <td
            colSpan={cellEntity.colSpan}
            style={cellEntity.style} className={cellEntity.classes} onClick={cellEntity.onClick}>
            <div>
                {/* Prefix e.g.(+,-) */}
                {cellEntity.contentPrefix}
                {/* Info Icon */}
                {ObjCheck.objHasKeys(cellEntity.metadata) &&
                    <FieldPopup {...cellEntity.metadata} showInfoIcons={showInfoIcons} />}
                {/* Actual Data */}
                {cellEntity.tooltip ?
                    <HeaderLabelPopup
                        content={cellEntity.tooltip}
                        trigger={
                            <span className='dataSpan'>
                                {cellEntity.data}
                            </span>} />
                    : dataSpan}
            </div>
        </td>
    </>)
}


function DataSpan({ cellEntity }) {
    const [chart, setChart] = useState(null)

    return <>
        {chart ?
            <Popup
                style={{ zIndex: 99 }}
                position="top left"
                positionFixed={true}
                on="click"
                content={chart}
                trigger={
                    <span className='dataSpan'>
                        {cellEntity.data}
                    </span>
                }
                onMount={() => window.scrollBy(0, 1)
                }
                onUnmount={() => setChart(null)}
            /> :
            <span className={cellEntity.getChart ? "cursorPointer dataSpan" : "dataSpan"}
                onClick={() => cellEntity.getChart ? setChart(cellEntity.getChart()) : ""}>
                {cellEntity.data}
            </span>}
    </>
}


function FieldPopup({ reportedAsLabel, notes, manuallyCalculated, src, p2pCalc,
    resourceFilterProvider, editFunc, dataDiffDetection, dataDiffExp,
    hasPreviewResourcesPrivs, hasPreviewLablesPrivs, showInfoIcons, dataSource }) {
    if (resourceFilterProvider !== undefined) {
        return <a target="_blank" rel="noopener noreferrer"
            href={hasPreviewResourcesPrivs ? "/resources/preview/" + btoa(JSON.stringify(resourceFilterProvider())) : "/notEnoughPriviliges"}>
            <Icon className={'cursorPointer publicCellIIcon'} color='grey'
                size='small' name='file alternate outline' />
        </a>
    } else if (editFunc !== undefined) {
        return <Icon className='cursorPointer publicCellIIcon' color='blue'
            size='small' name='edit' onClick={editFunc} />
    } else if (showInfoIcons) {
        const getContent = () => <>
            {manuallyCalculated && <><b>Calculated by 3D StockPicker</b><br /></>}
            {reportedAsLabel && <>
                <b>Reported as: </b>
                {hasPreviewLablesPrivs ? reportedAsLabel : NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT}<br />
            </>}
            {notes && <><b>Notes:</b> {notes}<br /></>}
            {src && <><b>Source:</b> {src}<br /></>}
            {p2pCalc && <><b>Period-To-Period Calculated</b><br />{p2pCalc}<br /></>}
            {dataDiffDetection && <><b>Data Inconsistency</b>
                <br />Detection method: {dataDiffDetection}
                {dataSource !== DATA_SOURCES.DATA_DIFF ? <><br />Expectation: {dataDiffExp}</> : <>
                    <br />The data is NOT presented in the current report and has been copied from the original.
                    <br /><b>Warning: The value maybe incorrect due to changes in the reporting standard.</b></>}
            </>}
        </>

        let color
        if (dataSource === DATA_SOURCES.DATA_DIFF) {
            color = ICON_COLORS.VIOLET
        } else if (dataDiffDetection) {
            color = ICON_COLORS.RED
        } else if (manuallyCalculated) {
            color = ICON_COLORS.GREY
        } else if (p2pCalc) {
            color = ICON_COLORS.YELLOW
        } else {
            color = ICON_COLORS.BLUE
        }

        return <Popup content={getContent()} trigger={
            <Icon className='publicCellIIcon cursorPointer' color={color} size='small'
                name='info' />} />
    } else {
        return <></>
    }
}

function HeaderLabelPopup({ content, trigger }) {
    return <Popup size='tiny' wide className='headerPopup'
        content={{ content }} trigger={trigger} />
}

function TableManipolationBtn() {
    const [enabled, setEnabled] = useState(false)
    return <>
        {!enabled && <Button size='mini' className='tableManipulationBtn' onClick={() => {
            const revertOperations = []
            document.querySelectorAll('.scaleColumn').forEach(bttn => bttn.addEventListener('click', function (e) {
                this.parentNode.style.display = 'none'
                revertOperations.push(() => {
                    this.parentNode.style.display = 'table-row'
                })
            }))

            document.querySelectorAll('.reportsTable tr:nth-child(1) td')
                .forEach(bttn => bttn.addEventListener('click', function (e) {
                    document.querySelectorAll('.reportsTable tr td:nth-child(' + (this.cellIndex + 1) + ')').forEach(function (btn) {
                        btn.style.display = 'none'
                    })

                    revertOperations.push(() => {
                        document.querySelectorAll('.reportsTable tr td:nth-child(' + (this.cellIndex + 1) + ')').forEach(function (btn) {
                            btn.style.display = 'table-cell'
                        })
                    })
                }))


            document.addEventListener('keydown', function (event) {
                if (event.ctrlKey && event.key === 'z') {
                    const op = revertOperations.pop()

                    if (op) {
                        op()
                    }
                }
            });
            setEnabled(true)
        }} >
            Enter Table Manipulation Mode
        </Button>}
    </>

}

const useFixedHeader = (tableRef, headerRefs) => {
    const tableWrapperRef = tableRef
    const headers = headerRefs
    const headerMenueHeight = 55

    useEffect(() => {

        if (headers.every(h => h.current) && tableRef.current) {
            const translate = () => {
                headers.forEach(header => {
                    header = header.current
                    if (!header) {
                        // Handle leave event during iteration
                        return
                    }
                    const table = tableWrapperRef.current;
                    const tablePosition = table.getBoundingClientRect();
                    const tableTop = tablePosition.top

                    if (tableTop - headerMenueHeight >= 0) {
                        header.style.removeProperty('transform')
                        header.classList.remove('fixedTableRow')
                    } else {
                        let translation
                        if (tableTop <= headerMenueHeight && tableTop > 0) {
                            translation = headerMenueHeight - Math.floor(tableTop)
                        } else {
                            translation = Math.floor(Math.abs(tableTop)) + headerMenueHeight + 1
                        }
                        header.style.setProperty('transform', `translateY(${translation}px)`)
                        header.classList.add('fixedTableRow');
                    }
                })
            }

            const scrollerTranslate = () => {
                const scroller = document.getElementById("h-scroll");
                const table = tableWrapperRef.current;
                let tableRect
                if (table && scroller) {
                    tableRect = table.getBoundingClientRect();
                } else {
                    return
                }

                const translation = Math.floor(window.innerHeight - tableRect.bottom - 12)
                if (translation < 0) {
                    scroller.style.setProperty('transform', `translateY(${translation}px)`)
                } else {
                    scroller.style.removeProperty('transform')
                }
            }

            window.addEventListener('scroll', translate)
            window.addEventListener('scroll', scrollerTranslate)
            window.addEventListener("resize", scrollerTranslate)

            return () => {
                window.removeEventListener('scroll', translate)
                window.removeEventListener('scroll', scrollerTranslate)
                window.removeEventListener("resize", scrollerTranslate)
            }
        }

        return () => {
        }
    })
}

export default Table