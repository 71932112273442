import { DescriptorProps } from "../../constnats/descriptor";
import { ReportField } from "../../constnats/reportConstants";
import Alert from "../alert";
import DescriptorType from "../descriptor/descriptorType";
import ReportUtils from "../reportUtils";
import ObjCheck from "../objCheck";
import Utils from "../utils";

const INPUT_SCALE = 1

/**
 * @param {Object} nonCategorizedReport Required
 * @param {Object} categorizedDescriptor Required
 * @returns {Object} not null
 */
const getCategorizedDescriptorMetaData = (nonCategorizedReport, categorizedDescriptor) => {
    let metadata
    if (!categorizedDescriptor[DescriptorProps.DYNAMIC]) {
        metadata = nonCategorizedReport[ReportField.METADATA].fieldSpecific[categorizedDescriptor[DescriptorProps.ID]]
    } else {
        const descriptorsIds = Object.keys(categorizedDescriptor[DescriptorProps.CATEGORY_RELATION_SIGNS])
        if (descriptorsIds.length === 1) {
            metadata = nonCategorizedReport[ReportField.METADATA].fieldSpecific[descriptorsIds[0]]
        } else {
            metadata = ReportUtils.getDefaultMetadata(INPUT_SCALE);
        }
    }
    return metadata;
}

/**
 * @param {Object} categorizedDescriptor Required
 * @param {Object} nonCategorizedReport Required
 * @returns {Object} not null
 */
const getCategorizedFieldValue = (categorizedDescriptor, nonCategorizedReport) => {
    let categorizedFieldValue;

    for (const descriptorId of Object.keys(categorizedDescriptor[DescriptorProps.CATEGORY_RELATION_SIGNS])) {
        let fieldValue = nonCategorizedReport[ReportField.DYNAMIC_FIELDS][descriptorId];
        if (ObjCheck.isNullUndefinedEmptyOrDash(fieldValue)) {
            continue;
        }
        if (DescriptorType.isNumber(categorizedDescriptor)) {
            if (Utils.isNumber(fieldValue)) {
                if (categorizedFieldValue === undefined) {
                    categorizedFieldValue = 0
                }

                fieldValue = Utils.toNumber(fieldValue);
                if (categorizedDescriptor[DescriptorProps.CATEGORY_RELATION_SIGNS][descriptorId]) {
                    fieldValue *= -1;
                }
                categorizedFieldValue += fieldValue
            } else {
                categorizedFieldValue = "ERROR"
                Alert.warn("Encounter a non number value for a number descriptor when merging categories!")
                break;
            }
        } else {
            categorizedFieldValue = fieldValue
        }
    }

    return categorizedFieldValue
}

/**
 * @param {Object[]} categorizedFlatDescriptors Required
 * @param {Object} nonCategorizedReport Required
 * @returns {Object} not null
 */
const getCategorizedReport = (categorizedFlatDescriptors, nonCategorizedReport) => {
    const categorizedReport = {
        [ReportField.ID]: nonCategorizedReport[ReportField.ID],
        [ReportField.MANAGED]: nonCategorizedReport[ReportField.MANAGED],
        [ReportField.METADATA]: { fieldSpecific: {} },
        [ReportField.DYNAMIC_FIELDS]: {}
    }

    for (const categorizedDescriptor of categorizedFlatDescriptors) {
        const categorizedDescriptorId = categorizedDescriptor[DescriptorProps.ID]

        categorizedReport[ReportField.METADATA].fieldSpecific[categorizedDescriptorId] = getCategorizedDescriptorMetaData(nonCategorizedReport, categorizedDescriptor)

        if (!categorizedDescriptor[DescriptorProps.DYNAMIC]) {
            categorizedReport[categorizedDescriptorId] = nonCategorizedReport[categorizedDescriptorId];
        } else {
            categorizedReport[ReportField.DYNAMIC_FIELDS][categorizedDescriptorId] = getCategorizedFieldValue(categorizedDescriptor, nonCategorizedReport)
        }
    }

    return categorizedReport
}

/**
 * @description Returns new reports based on the provided descriptors and non categorized reports. 
 * The categorization is executed based on the descriptorsIdsToMerge and categoryRelationSigns fields 
 * of the descriptors.
 * @param {Object[]} categorizedFlatDescriptors Required
 * @param {Object[]} nonCategorizedReports Required
 * @returns {Object[]} not null
 */
const getCategorizedReports = (categorizedFlatDescriptors, nonCategorizedReports) => {
    const categorizedReports = [];

    for (const report of nonCategorizedReports) {
        categorizedReports.push(getCategorizedReport(categorizedFlatDescriptors, report));
    }

    return categorizedReports
}

export { getCategorizedReports }
